<template>
  <!-- <b-table bordered borderless stacked :items="items"></b-table> -->
  <div class="package-details-table bg-white overflow-hidden rounded-14">

    <b-row class="">
      <b-col
        sm="12"
        class="package-details-name"
      >
        <h1 class="package-details-table__title text-font-main text-bold-16">
          {{ $t('orders_page.employer_details_title') }}
        </h1>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col
        sm="12"
        class="package-details-value"
      >
        <div class="user-info mb-0 w-100">
          <div class="user-info__body">
            <div class="personal-info">
              <img
                class="avatar"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoKJPxxwPeNvISnBbZsZHe887Ws0FnrL7o0w&usqp=CAU"
                alt=""
              >

              <p class="m-0 text-reg-16 text-font-main">
                {{ name }}
              </p>
            </div>
          </div>
        </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      items: [{ age: 40, first_name: 'Dickerson', last_name: 'Macdonald' }],
    };
  },
};
</script>
