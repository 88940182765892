<template>
  <div class="wameed-dashboard-page-content mb-5">
    <div>
      <page-header
        :title="$t('orders_page.details_title')"
        :sub-title="$t('orders_page.details_subtitle')"
      />
    </div>
    <section v-if="getOfferDetails" class="wameed-dashboard-page-content_body">
      <div class="mx-4">
        <b-row class="">
          <b-col md="10" class="my-4">
            <offer-details-table
              v-if="getOfferDetails"
              :id="getOfferDetails.id"
              :price="getOfferDetails.price"
              :description="getOfferDetails.description"
              :date="getOfferDetails.created_at"
              :file="getOfferDetails.file"
              :status="getOfferDetails.status"
              :employee="getOfferDetails.employee"
            />
          </b-col>
          <b-col md="6" class="my-4">
            <order-details-table
              v-if="getOfferDetails"
              :id="getOfferDetails.project.id"
              :title="getOfferDetails.project.hr_job_title"
              :employee-job-title="getOfferDetails.project.employee_job_title"
              :required-jobs-no="getOfferDetails.project.required_jobs_no"
              :date="getOfferDetails.project.created_at"
              :description="getOfferDetails.project.description"
              :industry-preference="
                getOfferDetails.project.industry_preference.name
              "
              :file="getOfferDetails.project.file"
              :role-preference="getOfferDetails.project.role_preference"
              :services="getOfferDetails.project.services"
            />
          </b-col>
          <b-col md="4" class="my-4" v-if="getOfferDetails">
            <employer-details-table
              v-if="getOfferDetails.employee_seeker"
              :id="getOfferDetails.employee_seeker.id"
              :name="getOfferDetails.employee_seeker.name"
            />
          </b-col>
        </b-row>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OfferDetailsTable from '@/components/pages/orders/OfferDetailsTable.vue';
import OrderDetailsTable from '@/components/pages/orders/OrderDetailsTable.vue';
import EmployerDetailsTable from '@/components/pages/orders/EmployerDetailsTable.vue';
import PageHeader from '@/components/PageHeader.vue';
import WameedBtn from '@/components/WameedBtn.vue';
export default {
  components: {
    OfferDetailsTable,
    OrderDetailsTable,
    EmployerDetailsTable,
    PageHeader,
    WameedBtn,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getOfferDetails: 'getOfferDetails',
    }),
  },
  mounted() {
    this.loadOffersDetails(this.$router.currentRoute.params.id);
  },

  methods: {
    ...mapActions({
      loadOffersDetails: 'loadOffersDetails',
      loadFile: 'loadFile',
      agreedOffer: 'agreedOffer',
    }),
    agreedOfferAction() {
      this.agreedOffer(this.$router.currentRoute.params.id);
    },
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
  },
};
</script>
